<template>
  <div class="pane_body">
    <v-card flat color="rgb(255 255 245)">
      <v-breadcrumbs
        :items="breadcrumb"
        divider="/"
        style="padding: 14px 20px;"
      ></v-breadcrumbs>
    </v-card>

    <v-container>
      <v-card color="transparent" flat>
        <v-toolbar flat>
          <v-toolbar-title>users</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="goback"
                icon
                class="mx-2"
                v-on="on"
                depressed
                dark
                color="primary"
              >
                <v-icon dark>mdi-keyboard-return</v-icon>
              </v-btn>
            </template>
            <span>Go back</span>
          </v-tooltip>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card flat rounded="0">
          <v-card-text>
            <v-form>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field label="first name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="last name"></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field label="mobile phone"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="email"></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12">
                  <v-select label="Role"></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="error">cancel</v-btn>
            <v-btn text color="primary">submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        breadcrumb: [
          {
            text: "Dashboard",
            disabled: false,
            href: "breadcrumbs_dashboard"
          },
          {
            text: "Medical Officers",
            disabled: true,
            href: "breadcrumbs_dashboard"
          }
        ],
        headers: [
          { text: "name", value: "fullname" },
          { text: "email", value: "email" },
          { text: "mobile phone", value: "mobile" },
          { text: "active", value: "active", align: "center" },
          { text: "date", value: "date" },
          { text: "actions", value: "actions", align: "center" }
        ],
        userslist: [
          {
            fullname: "Vernon Mckinney",
            email: "eu.ligula@sapiengravida.co.uk",
            mobile: "0698660438",
            subscriber_type: "doctors",
            active: true,
            date: "1580285723"
          },
          {
            fullname: "Alfonso Langley",
            email: "Sed.molestie.Sed@vehicula.co.uk",
            mobile: "0598149389",
            subscriber_type: "doctors",
            active: false,
            date: "1593894892"
          },
          {
            fullname: "Gregory Craft",
            email: "velit.justo@augueacipsum.org",
            mobile: "0688750553",
            subscriber_type: "doctors",
            active: true,
            date: "1633439103"
          }
        ]
      };
    },
    methods: {
      edit(id) {
        console.log(id);
      },
      goback() {
        window.history.length > 1
          ? this.$router.go(-1)
          : this.$router.push("/");
      }
    }
  };
</script>
